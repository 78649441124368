.steps-content {
    min-height: 200px;
    margin-top: 16px;
    margin-right: 50px;
    margin-left: 15px;
    padding-top: 80px;
    text-align: left;
    background-color: #fafafa;
    border: 2px dashed #e9e9e9;
    border-radius: 2px;
  }
  
  .steps-action {
    margin-top: 24px;
  }

  .class{
    width: 100%;
    margin: 85px;
   }

  